var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "list-container", style: { height: _vm.height } },
        [
          _c("div", { staticClass: "header" }, [_vm._v("配送平台管理")]),
          _c(
            "div",
            { staticClass: "toolbar" },
            [
              _c(
                "el-button",
                {
                  style: { marginTop: "15px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.add },
                },
                [_vm._v("新增配送平台")]
              ),
              _c(
                "el-input",
                {
                  style: { width: "250px", marginTop: "15px", float: "right" },
                  attrs: { placeholder: "查询关键字", clearable: true },
                  model: {
                    value: _vm.keyword,
                    callback: function ($$v) {
                      _vm.keyword = $$v
                    },
                    expression: "keyword",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: { click: _vm.search },
                    slot: "append",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "content" },
            _vm._l(_vm.ordFreightPlatformList, function (item) {
              return _c(
                "div",
                {
                  key: item.sid,
                  staticClass: "item",
                  on: {
                    click: function ($event) {
                      return _vm.view(item)
                    },
                  },
                },
                [
                  _vm._m(0, true),
                  _c("div", { staticClass: "infoContainer" }, [
                    _c(
                      "div",
                      { staticClass: "infoItem", attrs: { title: item.cName } },
                      [_vm._v(_vm._s(item.cName))]
                    ),
                    _c("div", { staticClass: "infoItem" }, [
                      _vm._v("ID: " + _vm._s(item.cCode)),
                    ]),
                  ]),
                ]
              )
            }),
            0
          ),
          _c("el-pagination", {
            style: { marginTop: "15px" },
            attrs: {
              background: "",
              layout: _vm.pageLayout,
              total: _vm.total,
              "current-page": _vm.pageNum,
              "page-sizes": _vm.pageSelect,
              "page-size": _vm.pageSize,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("AddOrEditPlatformDetail", {
        attrs: {
          visible: _vm.showAddOrEditPlatformDetail,
          sid: _vm.currentSid,
        },
        on: {
          "update:visible": function ($event) {
            _vm.showAddOrEditPlatformDetail = $event
          },
          close: _vm.handleCloseAddOrEditPlatformDetail,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "picContainer" }, [
      _c("div", { staticClass: "pic" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }