var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      attrs: {
        title: (_vm.sid ? "编辑" : "新增") + "配送平台",
        visible: _vm.dialogVisible,
        "append-to-body": "",
        size: "400px",
        "before-close": _vm.handleCancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "container",
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "95px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "应用名称", prop: "cName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.cName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "cName", $$v)
                      },
                      expression: "form.cName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "APP key", prop: "cAppKey" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.cAppKey,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "cAppKey", $$v)
                      },
                      expression: "form.cAppKey",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "APP secret", prop: "cAppSecret" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.cAppSecret,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "cAppSecret", $$v)
                      },
                      expression: "form.cAppSecret",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "接口地址", prop: "cInterfaceUrl" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.cInterfaceUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "cInterfaceUrl", $$v)
                      },
                      expression: "form.cInterfaceUrl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "回调地址", prop: "cCallUrl" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.cCallUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "cCallUrl", $$v)
                      },
                      expression: "form.cCallUrl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "充值地址", prop: "cRechargeUrl" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.cRechargeUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "cRechargeUrl", $$v)
                      },
                      expression: "form.cRechargeUrl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所在区域", prop: "cRegion" } },
                [
                  _c("el-cascader", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      options: _vm.baseArea,
                      props: { value: "NODE_CODE", label: "NODE_NAME" },
                      filterable: "",
                      clearable: "",
                    },
                    model: {
                      value: _vm.form.cRegion,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "cRegion", $$v)
                      },
                      expression: "form.cRegion",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "cRemark" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 6, placeholder: "请输入" },
                    model: {
                      value: _vm.form.cRemark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "cRemark", $$v)
                      },
                      expression: "form.cRemark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleCancel } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v("确认")]
              ),
              _vm.sid
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "danger", loading: _vm.loading },
                      on: { click: _vm.deletePlatform },
                    },
                    [_vm._v("删除")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }