<template>
  <div class="container">
    <div class="list-container" :style="{height: height}">
      <div class="header">配送平台管理</div>
      <div class="toolbar">
        <el-button type="primary" @click="add" :style="{marginTop: '15px'}">新增配送平台</el-button>
        <el-input placeholder="查询关键字" v-model="keyword" :clearable="true" :style="{ width:'250px',marginTop: '15px',float: 'right'}">
          <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
        </el-input>
      </div>
      <div class="content">
        <div v-for="item in ordFreightPlatformList" :key="item.sid" class="item" @click="view(item)">
          <div class="picContainer">
            <div class="pic"></div>
          </div>
          <div class="infoContainer">
            <div class="infoItem" :title="item.cName">{{item.cName}}</div>
            <div class="infoItem">ID: {{item.cCode}}</div>
          </div>
        </div>
      </div>
      <el-pagination
        background
        :layout="pageLayout"
        :total="total"
        :current-page="pageNum"
        :page-sizes="pageSelect"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :style="{marginTop: '15px'}">
      </el-pagination>
    </div>
    <AddOrEditPlatformDetail :visible.sync="showAddOrEditPlatformDetail" :sid="currentSid" @close="handleCloseAddOrEditPlatformDetail"/>
  </div>
</template>

<script>
import {getOrdFreightPlatform} from "@/api/delivery";
import AddOrEditPlatformDetail from "@/views/components/DeliveryPlatform/AddOrEditPlatformDetail";

export default {
  name: "deliveryPlatform",
  components: {AddOrEditPlatformDetail},
  data() {
    return {
      height: document.documentElement.clientHeight - 60 + "px",
      currentSid:'',
      showAddOrEditPlatformDetail: false,
      keyword:'',
      ordFreightPlatformList:[],
      loading:false,
      total: 0, //总条数
      pageNum: 1, //当前页
      pageSize: 10, //每页显示条数
      pageSelect: [10, 20, 30, 50],
      pageLayout:"prev, pager, next,jumper,total,sizes",
    };
  },
  created() {
    this.getOrdFreightPlatform();
  },
  mounted() {

  },
  methods: {
    getOrdFreightPlatform() {
      let params = {
        search:this.keyword,
        pageNum:this.pageNum,
        pageSize:this.pageSize,
      }
      this.loading = true;
      getOrdFreightPlatform(params).then(result => {
        if (result) {
          this.ordFreightPlatformList = result.rows;
          this.total = result.total;
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    // 查询
    search(){
      this.pageNum = 1;
      this.getOrdFreightPlatform();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.getOrdFreightPlatform();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getOrdFreightPlatform();
    },
    add(){
      this.currentSid = '';
      this.showAddOrEditPlatformDetail = true;
    },
    view(item){
      this.currentSid = item.sid;
      this.showAddOrEditPlatformDetail = true;
    },
    handleCloseAddOrEditPlatformDetail(){
      this.showAddOrEditPlatformDetail = false;
      this.pageNum = 1;
      this.getOrdFreightPlatform();
    },
  },
};
</script>

<style lang="scss" scoped>
.container{
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 20px;
}
.right-container{
  float:left;
  width:calc(100% - 240px);
  height: 100%;
}
.list-container{
  width: 100%;
  border: 1px solid #ddd;
  padding: 0px 20px;
  .header{
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    padding-left: 5px;
    border-bottom: 1px solid #ddd;
  }
  .toolbar{
    width: 100%;
    height: 60px;
  }
}
.content{
  width: 100%;
  height: auto;
  overflow: hidden;
  .item{
    width: 240px;
    height: 100px;
    margin: 12px;
    border: 1px solid #ddd;
    border-radius: 5px;
    float: left;
    cursor: pointer;
    .picContainer{
      width: 100px;
      height: 100px;
      float: left;
      .pic{
        width: 70px;
        height: 70px;
        margin: 15px;
        border: 1px solid #ddd;
      }
    }
    .infoContainer{
      width: calc(100% - 100px);
      height: 100px;
      float: left;
      padding: 15px 5px 15px 0px;
      .infoItem{
        width: 100%;
        height: 35px;
        line-height: 35px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
.link{
  color:#409EFF;
  cursor: pointer;
}
</style>
