<template>
  <el-drawer
    :title="(sid?'编辑':'新增')+'配送平台'"
    :visible.sync="dialogVisible"
    append-to-body
    :size="'400px'"
    :before-close="handleCancel"
    >
    <div class="container" v-loading="loading">
      <el-form :model="form" :rules="rules" ref="form" label-width="95px" label-position="left">
        <el-form-item label="应用名称" prop="cName">
          <el-input v-model="form.cName" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="APP key" prop="cAppKey">
          <el-input v-model="form.cAppKey" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="APP secret" prop="cAppSecret">
          <el-input v-model="form.cAppSecret" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="接口地址" prop="cInterfaceUrl">
          <el-input v-model="form.cInterfaceUrl" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="回调地址" prop="cCallUrl">
          <el-input v-model="form.cCallUrl" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="充值地址" prop="cRechargeUrl">
          <el-input v-model="form.cRechargeUrl" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="所在区域" prop="cRegion">
          <el-cascader
            v-model="form.cRegion"
            :options="baseArea"
            :props="{ value: 'NODE_CODE',label: 'NODE_NAME' }"
            filterable
            clearable
            style="width: 100%"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="备注" prop="cRemark">
          <el-input
            type="textarea"
            :rows="6"
            placeholder="请输入"
            v-model="form.cRemark">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer footer">
            <el-button @click="handleCancel">取 消</el-button>
            <el-button type="primary" @click="handleConfirm" :loading="loading">确认</el-button>
                  <el-button type="danger" @click="deletePlatform" :loading="loading" v-if="sid">删除</el-button>
          </span>
    </div>
  </el-drawer>
</template>
<script>
import {sexMap,statusMap,specialMap} from "@/utils/deliveryMap";
import baseArea from "@/utils/baseArea";
import {
  getOrdFreightPlatformDetail,
  addOrdFreightPlatform,
  editOrdFreightPlatform,
  deleteOrdFreightPlatform,
  deleteOrdFreightRider
} from "@/api/delivery";
import {removeToken} from "@/utils/auth";
export default {
  name: "AddOrEditPlatformDetail",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    sid: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading:false,
      regionKeyword:'',
      dialogVisible: this.visible,
      sexMap:sexMap,
      platformList:[],
      storeList: [],
      specialMap:specialMap,
      statusMap:statusMap,
      baseArea:baseArea['data'],
      form: {
        cName: '',
        cUrl: '',
        cAppKey: '',
        cAppSecret	:'',
        cInterfaceUrl:'',
        cCallUrl: '',
        cRechargeUrl: "",
        cRemark: "",
        cRegion: [],
      },
      rules: {
        cName: [
          { required: true, message: '请输入应用名称', trigger: 'blur' },
          { max: 1000, message: '长度不能超过100个字符', trigger: 'blur' }
        ],
        cAppKey: [
          { max: 1000, message: 'APP key不能超过1000个字符', trigger: 'blur' }
        ],
        cAppSecret: [
          { max: 1000, message: 'APP secret不能超过1000个字符', trigger: 'blur' }
        ],
        cInterfaceUrl: [
          { max: 1000, message: '接口地址不能超过1000个字符', trigger: 'blur' }
        ],
        cCallUrl: [
          { max: 1000, message: '回调地址不能超过1000个字符', trigger: 'blur' }
        ],
        cRechargeUrl: [
          { max: 1000, message: '充值地址不能超过1000个字符', trigger: 'blur' }
        ],
        cRemark: [
          { max: 2000, message: '备注不能超过2000个字符', trigger: 'blur' }
        ],
      },
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
    getOrdFreightPlatformDetail() {
      if(this.sid){
        this.loading = true;
        getOrdFreightPlatformDetail(this.sid).then(result => {
          if (result) {
            this.form = result.data;
            this.loading = false;
          }
        });
      }else{
        this.form = {
          cName: '',
          cUrl: '',
          cAppKey: '',
          cAppSecret	:'',
          cInterfaceUrl:'',
          cCallUrl: '',
          cRechargeUrl: "",
          cRemark: "",
          cRegion: [],
        };
      }
    },
    handleCancel() {
      this.$refs['form'].resetFields();
      this.dialogVisible = false;
    },
    handleConfirm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          let params = this.form;
          if(!this.sid){
            addOrdFreightPlatform(params).then(result => {
              if (result) {
                this.$message.success("新增成功");
                this.dialogVisible = false;
                this.$emit('close');
              }
            }).finally(() => {
              this.loading = false;
            });
          }else{
            editOrdFreightPlatform(params).then(result => {
              if (result) {
                this.$message.success("编辑成功");
                this.dialogVisible = false;
                this.$emit('close');
              }
            }).finally(() => {
              this.loading = false;
            });
          }
        } else {
          return false;
        }
      });
    },
    deletePlatform() {
      this.loading = true;
      this.$confirm('是否确认删除数据项？')
        .then((_) => {
          deleteOrdFreightPlatform(this.sid).then(result => {
            if (result) {
              this.$refs['form'].resetFields();
              this.dialogVisible = false;
              this.$emit('close');
              this.$message.success("删除成功");
            }
          }).finally(() => {
            this.loading = false;
          });
        }).finally(() => {
        this.loading = false;
      });
    },
  },
  watch: {
    visible(newVal) {
      if(newVal){
        this.getOrdFreightPlatformDetail();
      }
      this.dialogVisible = newVal;
    },
    dialogVisible(newVal) {
      this.$emit('update:visible', newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-drawer__header>:first-child {
  font-size: 16px;
  color: #000;
}
.container{
  width: 100%;
  height: 100%;
  padding: 20px;
}
.footer{
  margin-top: 30px;
  float: right;
 }
</style>
